import toast from 'react-hot-toast';
import { CopyIcon } from '../../../../assets/icons/CopyIcon';
import './styles.scss'
import settings from '../../../../settings.json'
const CopyLabelBox = ({label, value, customCSS}) => {

    const handleCopy = () => {
        const baseReplace = value.split("{{BASE_URL}}").join(settings.BASE_URL);
        navigator.clipboard.writeText(baseReplace);
        toast.success("Copiado para a área de transferência")
    }

    return (
        <div className='copy-label-box-container' style={customCSS}>
            <p>{label}</p>
            {typeof value === "object" &&
                value.map((item) => {
                    return (
                        <div className='box' key={item} style={{marginBottom: 5}}>
                            {item}
                            <div style={{cursor: "pointer"}} onClick={handleCopy}>
                                <CopyIcon />
                            </div>
                        </div>
                    )
                })
            }
           
            {typeof value === "string" &&
                <div className='box'>
                    {value}
                    <div style={{cursor: "pointer"}}  onClick={handleCopy}>
                        <CopyIcon />
                    </div>
                </div>
            }
        </div>
    )
}

export default CopyLabelBox;