import { Link } from 'react-router-dom';
import './styles.scss'
import { useEffect, useState } from 'react';

const NavButton = ({title, Icon, currentPath, path, active, subs}) => {
    const [opened, setOpened] = useState(false);

    const toggleSub = () => {
        setOpened(!opened)
    }

    return (
        <>
            {!subs &&
                <Link to={path} className={`btn-nav-container ${active && "btn-active"}`}>
                    <Icon />
                    <p>{title}</p>
                </Link>
            }
            {subs && 
               <>
                <div className={`btn-nav-container ${(active  || (subs && !!subs.find(item => item.path === currentPath))) && "btn-active"}`} onClick={toggleSub}>
                    <Icon />
                    <p>{title}</p>
                </div>
                <div className={`subs-container ${opened && "sub-opened"}`}>
                    {
                        subs.map(item => {

                            if(item.disabled){
                                return (
                                    <div className={`sub-item ${item.disabled && "disabled"}`} >
                                        ● {item.title}
                                    </div>
                                )
                            }

                            return (
                                <Link key={item.path} to={item.path} className={`sub-item ${currentPath === item.path && "sub-active"}`}>
                                    <div className={`${item.disabled && "disabled"}`}>
                                        ● {item.title}
                                    </div>
                                </Link>
                            )
                        })
                    }
                </div>
               </>

            }
        </>
    )
}

export default NavButton;