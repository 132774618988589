import './styles.scss'
import Components from "..";

const Row = ({child, customCSS}) => {
    return (
        <div className='row-container' style={customCSS}>
            {child && child.map((item, index) => {
                const Element = Components[item.type];
                if(Element){
                    return <Element key={index} {...item} />
                }
            })
            }
        </div>
    )
}

export default Row;