// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body, html {
  width: 100%;
  height: 100vh !important;
  min-height: 100vh !important;
  background: white !important;
}

#root {
  width: 100%;
  height: 100%;
  position: relative;
}

#content {
  padding: 10px;
  overflow: auto;
  width: 100%;
  height: 100%;
}

.root-overrided {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.sidebar {
  width: 300px;
  height: 100%;
  position: relative;
  background: #FAFAFA;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 2px solid #F1F1F1;
}
.sidebar #logo {
  margin-top: 30px;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/layout/base/index.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,wBAAA;EACA,4BAAA;EACA,4BAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;AACJ;;AAEA;EACI,aAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;AACJ;;AAEA;EACI,YAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,+BAAA;AACJ;AACI;EACI,gBAAA;EACA,mBAAA;AACR","sourcesContent":["body, html {\n    width: 100%;\n    height: 100vh !important;\n    min-height: 100vh !important;\n    background: white !important;\n}\n\n#root {\n    width: 100%;\n    height: 100%;\n    position: relative;\n}\n\n#content {\n    padding: 10px;\n    overflow: auto;\n    width: 100%;\n    height: 100%;\n}\n\n.root-overrided {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: row;\n}\n\n.sidebar {\n    width: 300px;\n    height: 100%;\n    position: relative;\n    background: #FAFAFA;\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    border-right: 2px solid #F1F1F1;\n\n    #logo {\n        margin-top: 30px;\n        margin-bottom: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
