import './styles.scss'
import Components from "..";

export const Split = ({number, styles, direction, customCSS, child}) => {
    return (
        <div style={{...customCSS, flexDirection: direction}} className="split-container">
            {Array(number).fill("*").map((item, index) => {
                const style = styles[index];
                const children = child[index];
                return (
                    <div key={index} style={style} className='split-item'>
                        {children && children.map((item, index) => {
                            const Element = Components[item.type];
                            if(Element){
                                return <Element key={index} {...item} />
                            }
                        })}
                    </div>
                )
            })
            }
        </div>
    )
}