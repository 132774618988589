import PageTitle from '../page-maker/components/PageTitle';
import { TextOnly } from '../page-maker/components/TextOnly/TextOnly';
import './table.scss';

const OrderStatusPage = () => {
    return (
        <>
            <PageTitle title={"Tabela de status"} />
            <TextOnly value={"Confira a descrição de cada status do pedido"} />
            <table>
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Descrição</th>
                        <th>Descrição detalhada</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>CREATED</td>
                        <td>Pedido criado</td>
                        <td>Primeiro status após a criação do pedido, dura em torno de 5 a 10 segundos</td>
                    </tr>
                    <tr>
                        <td>PENDING</td>
                        <td>Pedido pendente</td>
                        <td>Início da busca por motoboy (Esse status também vale como busca para motoboy)</td>
                    </tr>
                    <tr>
                        <td>SEARCHING_FOR_DELIVERY</td>
                        <td>Pesquisando por motoboy</td>
                        <td>Buscando por motoboy</td>
                    </tr>
                    <tr>
                        <td>WAITING_DELIVERY_PICKUP</td>
                        <td>Aguardando retirada do motoboy</td>
                        <td>Após o motoboy aceitar o pedido, o status aguardando retirada irá ser aplicado ao pedido</td>
                    </tr>
                    <tr>
                        <td>DELIVERY_PICKEDUP</td>
                        <td>Motoboy retirou o pedido</td>
                        <td>Após o motoboy retirar o pedido da loja este status irá ser aplicado</td>
                    </tr>
                    <tr>
                        <td>COMPLETED</td>
                        <td>Pedido completado</td>
                        <td>Pedido finalizado - entrega realizada com sucesso</td>
                    </tr>
                    <tr>
                        <td>WAITING_CODE</td>
                        <td>Aguardando o código de confirmação</td>
                        <td>Quando o motoboy marca que está no endereço este status é aplicado</td>
                    </tr>
                    <tr>
                        <td>CANCELED</td>
                        <td>Pedido cancelado</td>
                        <td>Pedido cancelado</td>
                    </tr>
                    <tr>
                        <td>CANCELED_BY_DELIVERY</td>
                        <td>Pedido cancelado pelo motoboy</td>
                        <td>Pedido cancelado pelo o motoboy</td>
                    </tr>
                    <tr>
                        <td>CANCELED_BY_STORE</td>
                        <td>Pedido cancelado pela loja</td>
                        <td>Pedido cancelado pela loja</td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

export default OrderStatusPage;