import PageTitle from "./PageTitle";
import Row from "./Row/Row";
import CopyLabelBox from "./CopyLabelBox/CopyLabelBox";
import LabelBox from "./LabelBox/LabelBox";
import { TextOnly } from "./TextOnly/TextOnly";
import { Split } from "./Split/Split";
import { RequestMethod } from "./RequestMethod/RequestMethod";
import { CodeBlock } from "./CodeBlock/CodeBlock";
import StatusCodeBox from "./StatusCodeBox/StatusCodeBox";
import ImageBlock from "./ImageBlock/ImageBlock";

const Components = {
    Row: Row,
    PageTitle,
    TextOnly,
    CopyLabelBox,
    LabelBox,
    Split,
    CodeBlock,
    RequestMethod,
    StatusCodeBox,
    ImageBlock
}

export default Components;