import { useEffect, useState } from "react";
import dataset from '../../dataset.json'
import Components from "./components";

const PageMaker = ({page}) => {
    const [pageData, setPageData] = useState(dataset.pages[page]);

    useEffect(() => {
        setPageData(dataset.pages[page])
    }, [page])

    return (
        <div style={{width: "100%"}}>
           {pageData && pageData.map((item, index) => {
            const Element = Components[item.type];
            if(Element){
                return <Element key={index} {...item} />
            }
           })
           }
        </div>
    )
}

export default PageMaker;