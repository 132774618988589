// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

table, th, td {
  border: 1px solid #ddd;
}

th, td {
  padding: 12px;
  text-align: left;
}

th {
  background-color: #f4f4f4;
  font-weight: bold;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}`, "",{"version":3,"sources":["webpack://./src/pages/schemas/table.scss"],"names":[],"mappings":"AACA;EACI,WAAA;EACA,yBAAA;EACA,gBAAA;AAAJ;;AAGA;EACI,sBAAA;AAAJ;;AAGA;EACI,aAAA;EACA,gBAAA;AAAJ;;AAGA;EACI,yBAAA;EACA,iBAAA;AAAJ;;AAGA;EACI,yBAAA;AAAJ;;AAGA;EACI,yBAAA;AAAJ","sourcesContent":["\ntable {\n    width: 100%;\n    border-collapse: collapse;\n    margin-top: 20px;\n}\n\ntable, th, td {\n    border: 1px solid #ddd;\n}\n\nth, td {\n    padding: 12px;\n    text-align: left;\n}\n\nth {\n    background-color: #f4f4f4;\n    font-weight: bold;\n}\n\ntr:nth-child(even) {\n    background-color: #f9f9f9;\n}\n\ntr:hover {\n    background-color: #f1f1f1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
