import './styles.scss'

export const RequestMethod = ({method, title, customCSS}) => {
    return (
        <div className='request-method-container' style={customCSS}>
            <p className={`method ${method}`}>{method.toUpperCase()}</p>
            <p className='split'>-</p>
            <p className="title">{title}</p>
        </div>
    )
}