import './styles.scss'
const LabelBox = ({label, value, customCSS}) => {
    return (
        <div className='label-box-container' style={customCSS}>
            <p>{label}</p>
            {typeof value === "object" &&
                value.map((item, index) => {
                    return (
                        <div className='box' key={index} style={{marginBottom: 5}}>
                            {item}
                        </div>
                    )
                })
            }
           
            {typeof value === "string" &&
                <div className='box'>
                    {value}
                </div>
            }
        </div>
    )
}

export default LabelBox;