import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LayoutBase from '../layout/base';
import PageMaker from '../pages/page-maker';
import OrderStatusPage from '../pages/schemas/orderStatus';
import WebhookEventsPage from '../pages/schemas/webhookEvents';

function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LayoutBase />}>
          <Route index  element={<PageMaker page={"/"} />}/>
          <Route path="/auth" element={<PageMaker page={"/auth"} />} />
          <Route path="/store" element={<PageMaker page={"/store"} />} />
          <Route path="/store/list" element={<PageMaker page={"/store/list"} />} />
          <Route path="/wallet/balance" element={<PageMaker page={"/wallet/balance"} />} />
          <Route path="/orders/list" element={<PageMaker page={"/orders/list"} />} />
          <Route path="/orders/create" element={<PageMaker page={"/orders/create"} />} />
          <Route path="/orders/search" element={<PageMaker page={"/orders/search"} />} />
          <Route path="/availability" element={<PageMaker page={"/availability"} />} />
          <Route path="/schemas/orderStatus" element={<OrderStatusPage />} />
          <Route path="/schemas/webhook/events" element={<WebhookEventsPage />} />
          <Route path="/webhook/create" element={<PageMaker page={"/webhook/create"} />} />
          <Route path="/webhook/list" element={<PageMaker page={"/webhook/list"} />} />
          <Route path="/webhook/update" element={<PageMaker page={"/webhook/update"} />} />
          <Route path="/webhook/delete" element={<PageMaker page={"/webhook/delete"} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes