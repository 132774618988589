import PageTitle from '../page-maker/components/PageTitle';
import { TextOnly } from '../page-maker/components/TextOnly/TextOnly';
import './table.scss';

const WebhookEventsPage = () => {
    return (
        <>
            <PageTitle title={"Tabela de eventos do Webhook"} />
            <TextOnly value={"Confira a descrição de cada evento enviado pelo webhook"} />
            <table>
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Descrição</th>
                        <th>Descrição detalhada</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>ORDER_CREATED</td>
                        <td>Pedido criado</td>
                        <td>Enviado quando um pedido é criado</td>
                    </tr>
                    <tr>
                        <td>ORDER_ACCEPTED</td>
                        <td>Pedido aceito</td>
                        <td>Enviado quando um pedido é aceito</td>
                    </tr>
                    <tr>
                        <td>ORDER_CANCELED</td>
                        <td>Pedido cancelado</td>
                        <td>Enviado quando um pedido é cancelado</td>
                    </tr>
                    <tr>
                        <td>ORDER_DELIVERY_PICKEDUP</td>
                        <td>Pedido retirado</td>
                        <td>Enviado quando o entregador retira o pedido (Ponto A - Loja)</td>
                    </tr>
                    <tr>
                        <td>ORDER_WAITING_CODE</td>
                        <td>Entregador no local</td>
                        <td>Enviado quando o entregador marca que chegou no endereço (Ponto B - Cliente)</td>
                    </tr>
                    <tr>
                        <td>ORDER_COMPLETED</td>
                        <td>Pedido completado</td>
                        <td>Enviado quando o entregador finaliza a entrega</td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

export default WebhookEventsPage;