const ViabilityIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 2V22" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2 2V22" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5555 6.44439V17.5555C17.5555 18.7828 16.5606 19.7777 15.3333 19.7777H8.66668C7.43938 19.7777 6.44446 18.7828 6.44446 17.5555V6.44439C6.44446 5.21709 7.43938 4.22217 8.66668 4.22217H15.3333C16.5606 4.22217 17.5555 5.21709 17.5555 6.44439Z" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

    )
}

export default ViabilityIcon;