import { Outlet, useLocation } from 'react-router-dom';
import NavButton from '../../components/NavButton';
import './index.scss'
import { useEffect, useState } from 'react';
import dataset from '../../dataset.json';
import Icons from '../../assets/icons';

const LayoutBase = () => {
    const [currentPath, setCurrentPath] = useState("/")

    const location = useLocation();

    useEffect(() => {
        if(location){
            setCurrentPath(location.pathname);
        }
    }, [location])

    return (
        <div className='root-overrided'>
            <div className="sidebar">
                <Icons.navbar.LogoIcon />
                {dataset.menu.map(item => {
                        return <NavButton key={item.path} currentPath={location.pathname} title={item.title} path={item.path} Icon={Icons.navbar[item.icon]} subs={item.subs} active={currentPath === item.path} />
                    })
                }

            </div>
            <div id="content">
                <Outlet />
            </div>
        </div>
    )
}

export default LayoutBase;