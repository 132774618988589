// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.request-method-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}
.request-method-container .method {
  font-size: 18px;
  font-weight: bold;
}
.request-method-container .title {
  font-size: 18px;
}
.request-method-container .split {
  margin-left: 5px;
  margin-right: 5px;
}
.request-method-container .post {
  color: rgb(255, 115, 0);
}
.request-method-container .delete {
  color: rgb(255, 0, 0);
}
.request-method-container .put {
  color: blue;
}
.request-method-container .get {
  color: green;
}`, "",{"version":3,"sources":["webpack://./src/pages/page-maker/components/RequestMethod/styles.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;AACJ;AACI;EACI,eAAA;EACA,iBAAA;AACR;AAEI;EACI,eAAA;AAAR;AAGI;EACI,gBAAA;EACA,iBAAA;AADR;AAII;EACI,uBAAA;AAFR;AAKI;EACI,qBAAA;AAHR;AAMI;EACI,WAAA;AAJR;AAOI;EACI,YAAA;AALR","sourcesContent":[".request-method-container {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    margin-top: 10px;\n\n    .method {\n        font-size: 18px;\n        font-weight: bold;\n    }\n\n    .title {\n        font-size: 18px;\n    }\n\n    .split {\n        margin-left: 5px;\n        margin-right: 5px;\n    }\n\n    .post {\n        color: rgb(255, 115, 0);\n    }\n\n    .delete {\n        color: rgb(255, 0, 0);\n    }\n\n    .put {\n        color: blue;\n    }\n\n    .get {\n        color: green;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
