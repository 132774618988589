const WebhookIcon = () => {
    return (
        <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.80196 6.97275C9.80196 5.21944 11.1718 3.79688 12.8667 3.79688C14.0188 3.79688 15.0247 4.455 15.548 5.43375C15.6234 5.58321 15.7269 5.71549 15.8523 5.82281C15.9777 5.93013 16.1226 6.01031 16.2783 6.05865C16.434 6.10698 16.5974 6.1225 16.759 6.10427C16.9205 6.08604 17.0769 6.03445 17.219 5.95252C17.3611 5.87059 17.486 5.75999 17.5863 5.62721C17.6866 5.49443 17.7603 5.34216 17.8031 5.17936C17.8459 5.01655 17.8569 4.84651 17.8355 4.67922C17.814 4.51193 17.7606 4.35078 17.6783 4.20525C17.2019 3.31398 16.5044 2.57136 15.6583 2.05446C14.8122 1.53755 13.8484 1.26518 12.8667 1.26563C12.1445 1.26496 11.4293 1.41208 10.7618 1.69857C10.0944 1.98506 9.48788 2.40531 8.97691 2.93531C8.46593 3.46531 8.06053 4.09467 7.78386 4.78743C7.50719 5.48019 7.36468 6.22278 7.36446 6.97275C7.36446 8.559 7.99009 9.99338 8.99434 11.0261L5.65659 17.091C5.14514 17.1585 4.67755 17.4255 4.34863 17.8378C4.0197 18.2501 3.85405 18.7768 3.88526 19.3112C3.91647 19.8456 4.1422 20.3477 4.51669 20.7157C4.89118 21.0837 5.3864 21.29 5.90196 21.2929H5.91334C6.25066 21.293 6.5827 21.2058 6.87948 21.0393C7.17625 20.8728 7.42842 20.6322 7.61322 20.3391C7.79803 20.0461 7.90964 19.7098 7.93798 19.3608C7.96633 19.0117 7.91052 18.6608 7.77559 18.3398L11.6252 11.3468C11.7806 11.0642 11.8253 10.7304 11.7499 10.4146C11.6746 10.0988 11.485 9.82531 11.2206 9.65081C10.786 9.36472 10.428 8.96921 10.18 8.50101C9.93202 8.03281 9.80197 7.50712 9.80196 6.97275Z" fill="black"/>
<path d="M12.6636 9.0702C12.1442 9.01718 11.6644 8.7586 11.3237 8.34809C10.983 7.93757 10.8075 7.40663 10.8336 6.86536C10.8597 6.32409 11.0855 5.81402 11.464 5.44095C11.8425 5.06787 12.3448 4.86041 12.8667 4.86158H12.8764C13.2101 4.86136 13.5387 4.9465 13.8331 5.10947C14.1275 5.27244 14.3788 5.50822 14.5646 5.79596C14.7505 6.08371 14.8652 6.41457 14.8987 6.75931C14.9322 7.10404 14.8834 7.45203 14.7566 7.77251L18.2649 13.8003C19.0941 13.496 19.9815 13.4028 20.8525 13.5284C21.7236 13.6541 22.5529 13.9949 23.2709 14.5222C23.9888 15.0496 24.5745 15.7481 24.9787 16.5592C25.3828 17.3702 25.5938 18.2702 25.5937 19.1834C25.5936 20.0548 25.4015 20.9146 25.0319 21.697C24.6624 22.4794 24.1253 23.1635 23.4618 23.697C22.7983 24.2304 22.0259 24.5991 21.204 24.7747C20.3821 24.9503 19.5324 24.9281 18.7199 24.71C18.5603 24.6733 18.4094 24.6036 18.2762 24.5051C18.143 24.4066 18.0302 24.2813 17.9447 24.1366C17.8591 23.9919 17.8024 23.8308 17.778 23.6629C17.7536 23.495 17.762 23.3238 17.8027 23.1593C17.8434 22.9948 17.9155 22.8406 18.0148 22.7056C18.1141 22.5707 18.2385 22.4579 18.3807 22.374C18.5228 22.2901 18.6798 22.2367 18.8422 22.2171C19.0046 22.1974 19.1692 22.2119 19.3261 22.2597C19.7784 22.3819 20.2517 22.3948 20.7096 22.2975C21.1676 22.2003 21.5979 21.9953 21.9677 21.6985C22.3375 21.4016 22.6369 21.0207 22.8429 20.585C23.0489 20.1494 23.1561 19.6705 23.1562 19.1851C23.1566 18.615 23.0093 18.0552 22.7297 17.5646C22.4501 17.074 22.0485 16.6705 21.5671 16.3964C21.0857 16.1224 20.5422 15.9879 19.9935 16.007C19.4448 16.0262 18.9112 16.1983 18.4486 16.5053C18.3115 16.596 18.1584 16.6573 17.9981 16.6856C17.8379 16.7138 17.6739 16.7086 17.5157 16.6701C17.3574 16.6315 17.2082 16.5605 17.0768 16.4612C16.9454 16.362 16.8344 16.2364 16.7504 16.0919L12.6636 9.0702Z" fill="black"/>
<path d="M3.25323 17.5989C3.33346 17.4551 3.38564 17.2963 3.40677 17.1315C3.4279 16.9668 3.41757 16.7993 3.37638 16.6387C3.33518 16.4781 3.26393 16.3275 3.16668 16.1954C3.06943 16.0634 2.9481 15.9526 2.8096 15.8693C2.5299 15.701 2.19728 15.655 1.88491 15.7414C1.57255 15.8278 1.30602 16.0395 1.14398 16.3299C0.661934 17.198 0.408407 18.1825 0.408875 19.1846C0.409343 20.1866 0.66379 21.1709 1.14664 22.0385C1.6295 22.9061 2.32375 23.6264 3.15962 24.127C3.99549 24.6277 4.94354 24.891 5.90848 24.8906C8.52473 24.8906 10.7185 22.9939 11.2726 20.4491H18.4551C18.8272 20.9621 19.4171 21.2929 20.0817 21.2929H20.0931C20.6318 21.2929 21.1485 21.0706 21.5294 20.6751C21.9103 20.2795 22.1244 19.7429 22.1244 19.1835C22.1244 18.6241 21.9103 18.0875 21.5294 17.692C21.1485 17.2964 20.6318 17.0741 20.0931 17.0741H20.0817C19.4171 17.0741 18.8272 17.4066 18.4567 17.9179H10.1904C9.86712 17.9179 9.55713 18.0512 9.32857 18.2886C9.10001 18.5259 8.9716 18.8478 8.9716 19.1835C8.97139 19.6011 8.89192 20.0145 8.73775 20.4002C8.58358 20.7858 8.35772 21.1361 8.07309 21.4311C7.78846 21.726 7.45064 21.9598 7.07894 22.1191C6.70724 22.2784 6.30895 22.36 5.90685 22.3594C5.37025 22.3595 4.84307 22.213 4.37819 21.9347C3.91331 21.6564 3.52708 21.256 3.25825 20.7737C2.98942 20.2915 2.84744 19.7443 2.84656 19.187C2.84568 18.6298 2.98592 18.0821 3.25323 17.5989Z" fill="black"/>
</svg>

    )
}

export default WebhookIcon