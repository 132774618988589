import { ListIcon } from "./ListIcon";
import { LockIcon } from "./LockIcon";
import { OrderIcon } from "./OrderIcon";
import { PackageIcon } from "./PackageIcon";
import { PlayConfigurationIcon } from "./PlayConfigurationIcon";
import { StoreIcon } from "./StoreIcon";
import { WalletIcon } from "./WalletIcon";
import { LogoIcon } from "./Logo";
import ViabilityIcon from "./Viability";
import WebhookIcon from "./webhook";

const Icons = {
    navbar: {
        "LockIcon": LockIcon,
        "OrderIcon": OrderIcon,
        "PlayConfigurationIcon": PlayConfigurationIcon,
        "WalletIcon": WalletIcon,
        StoreIcon,
        PackageIcon,
        ListIcon,
        WebhookIcon,
        LogoIcon,
        ViabilityIcon
    }
}

export default Icons;