import JSONPretty from 'react-json-pretty';
import './styles.scss'
var JSONPrettyMon = require('react-json-pretty/dist/monikai');
const StatusCodeBox = ({label, contentType, response, customCSS}) => {
    return (
        <div className='status-code-box-container' style={customCSS}>
            <p>{label}</p>
            <div className='box'>
                <JSONPretty
                    data={response}
                    style={{borderRadius: 10}}
                    stringStyle={"font-size: 14px"}
                    keyStyle='font-size: 14px'
                    theme={{
                        main: 'line-height:1.3;color:#000000;background:#FAFAFA;overflow:auto;',
                        error: 'line-height:1.3;color:#66d9ef;background:#272822;overflow:auto;',
                        key: 'color:#3680f0;',
                        string: 'color:#d45039;',
                        value: 'color:#348332;',
                        boolean: 'color:#d45039;',
                    }}              
                />
                <p className='response-type'>Content-Type: {contentType}</p>
            </div>
        </div>
    )
}

export default StatusCodeBox;