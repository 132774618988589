export const ListIcon = () => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.417 4.33337H7.58366C6.38704 4.33337 5.41699 5.30342 5.41699 6.50004V20.5834C5.41699 21.78 6.38704 22.75 7.58366 22.75H18.417C19.6136 22.75 20.5837 21.78 20.5837 20.5834V6.50004C20.5837 5.30342 19.6136 4.33337 18.417 4.33337Z" stroke="#33363F" stroke-width="3"/>
            <path d="M9.75 9.75H16.25" stroke="#33363F" stroke-width="3" stroke-linecap="round"/>
            <path d="M9.75 14.0834H16.25" stroke="#33363F" stroke-width="3" stroke-linecap="round"/>
            <path d="M9.75 18.4166H14.0833" stroke="#33363F" stroke-width="3" stroke-linecap="round"/>
        </svg>

    )
}