export const PlayConfigurationIcon = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1360_1292)">
                <path d="M5.5 0V8.25H7.8375L8.1125 9.2125L8.3875 10.0375H8.525L10.175 9.2125L12.65 11.6875L11.825 13.3375V13.475L12.65 13.75L13.6125 14.025V14.3L22 9.625L5.5 0Z" fill="black"/>
                <path d="M6.1875 14.4375C5.9125 14.4375 5.6375 14.575 5.5 14.7125C5.0875 14.9875 4.8125 15.4 4.8125 15.8125C4.8125 16.225 5.0875 16.775 5.5 16.9125C5.6375 17.05 5.9125 17.1875 6.1875 17.1875C7.0125 17.1875 7.5625 16.6375 7.5625 15.8125C7.5625 14.9875 7.0125 14.4375 6.1875 14.4375Z" fill="black"/>
                <path d="M12.375 16.5V15.125L10.8625 14.575C10.725 14.1625 10.5875 13.75 10.3125 13.3375L11 11.9625L10.0375 11L8.6625 11.6875C8.25 11.4125 7.8375 11.275 7.425 11.1375L6.875 9.625H5.5L4.95 11.1375C4.5375 11.275 4.125 11.4125 3.7125 11.6875L2.3375 11L1.375 11.9625L2.0625 13.475C1.7875 13.8875 1.65 14.3 1.5125 14.7125L0 15.125V16.5L1.5125 17.05C1.65 17.4625 1.7875 17.875 2.0625 18.2875L1.375 19.6625L2.3375 20.625L3.85 19.9375C4.2625 20.2125 4.675 20.35 5.0875 20.4875L5.5 22H6.875L7.425 20.4875C7.8375 20.35 8.25 20.2125 8.6625 19.9375L10.0375 20.625L11 19.6625L10.3125 18.15C10.5875 17.7375 10.725 17.325 10.8625 16.9125L12.375 16.5ZM6.1875 18.5625C4.675 18.5625 3.4375 17.325 3.4375 15.8125C3.4375 14.3 4.675 13.0625 6.1875 13.0625C7.7 13.0625 8.9375 14.3 8.9375 15.8125C8.9375 17.325 7.7 18.5625 6.1875 18.5625Z" fill="black"/>
            </g>
            <defs>
                <clipPath id="clip0_1360_1292">
                <rect width="22" height="22" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}