// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-only {
  font-size: 14px;
  max-width: 90%;
}`, "",{"version":3,"sources":["webpack://./src/pages/page-maker/components/TextOnly/styles.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,cAAA;AACJ","sourcesContent":[".text-only {\n    font-size: 14px;\n    max-width: 90%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
