// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label-box-container {
  width: 100%;
  height: auto;
}
.label-box-container p {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}
.label-box-container .box {
  width: 100%;
  height: 60px;
  background: #FAFAFA;
  display: flex;
  border: 1.5px solid #F1F1F1;
  border-radius: 10px;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 10px;
  padding-left: 10px;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/page-maker/components/LabelBox/styles.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;AACJ;AACI;EACI,eAAA;EACA,gBAAA;EACA,kBAAA;AACR;AAEI;EACI,WAAA;EACA,YAAA;EACA,mBAAA;EACA,aAAA;EACA,2BAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;AAAR","sourcesContent":[".label-box-container {\n    width: 100%;\n    height: auto;\n\n    p {\n        font-size: 16px;\n        font-weight: 500;\n        margin-bottom: 5px;\n    }\n\n    .box {\n        width: 100%;\n        height: 60px;\n        background: #FAFAFA;\n        display: flex;\n        border: 1.5px solid #F1F1F1;\n        border-radius: 10px;\n        flex-direction: row;\n        justify-content: space-between;\n        padding-right: 10px;\n        padding-left: 10px;\n        align-items: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
