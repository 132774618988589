import {CopyBlock, CodeBlock as ReactCodeBlock} from 'react-code-blocks'
import './styles.scss'
import { monokai } from 'react-code-blocks'
export const CodeBlock = ({title, language, code, customCSS}) => {
    return (
        <div className="code-box-container" style={customCSS}>
            <p className="title">{title}</p>
            <CopyBlock
                language={language}
                text={code}
                showLineNumbers={false}
                customStyle={{fontSize: 14}}
                theme={monokai}
                wrapLongLines
                codeBlock
            />
        </div>
    )
}