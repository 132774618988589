// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-nav-container {
  width: 80%;
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  padding-left: 20px;
  margin-top: 10px;
  cursor: default !important;
  -webkit-user-select: none !important;
          user-select: none !important;
}
.btn-nav-container p {
  font-size: 14px;
  margin-left: 15px;
}

.subs-container {
  width: 80%;
  height: 0px;
  margin-top: 5px;
  overflow: hidden;
  display: flex;
  padding-left: 40px;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
}
.subs-container .sub-item {
  width: 100%;
  margin-top: 5px;
  height: 35px;
  font-size: 14px;
}
.subs-container .disabled {
  color: darkgray;
}

.sub-opened {
  height: auto;
}

.sub-active {
  color: red;
}

.btn-active {
  background: black;
}
.btn-active p {
  color: white;
}
.btn-active svg {
  fill: white;
  stroke: white !important;
}`, "",{"version":3,"sources":["webpack://./src/components/NavButton/styles.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,0BAAA;EAOA,oCAAA;UAAA,4BAAA;AALJ;AAAI;EACI,eAAA;EACA,iBAAA;AAER;;AAKA;EACI,UAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;EACA,aAAA;EACA,kBAAA;EACA,sBAAA;EACA,gCAAA;AAFJ;AAII;EACI,WAAA;EACA,eAAA;EACA,YAAA;EACA,eAAA;AAFR;AAMI;EACI,eAAA;AAJR;;AASA;EACI,YAAA;AANJ;;AASA;EACI,UAAA;AANJ;;AASA;EACI,iBAAA;AANJ;AAQI;EACI,YAAA;AANR;AASI;EACI,WAAA;EACA,wBAAA;AAPR","sourcesContent":[".btn-nav-container {\n    width: 80%;\n    height: 45px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    border-radius: 10px;\n    padding-left: 20px;\n    margin-top: 10px;\n    cursor: default !important;\n    \n    p {\n        font-size: 14px;\n        margin-left: 15px;\n    }\n\n    user-select: none !important;\n\n}\n\n.subs-container {\n    width: 80%;\n    height: 0px;\n    margin-top: 5px;\n    overflow: hidden;\n    display: flex;\n    padding-left: 40px;\n    flex-direction: column;\n    transition: all 0.3s ease-in-out;\n\n    .sub-item {\n        width: 100%;\n        margin-top: 5px;\n        height: 35px;\n        font-size: 14px;\n        \n    }\n\n    .disabled {\n        color: darkgray;  \n    }\n\n}\n\n.sub-opened {\n    height: auto;\n}\n\n.sub-active {\n    color: red;\n}\n\n.btn-active {\n    background: black;\n\n    p {\n        color: white;\n    }\n\n    svg {\n        fill: white ;\n        stroke: white !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
