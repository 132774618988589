// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-code-box-container {
  width: 100%;
  height: auto;
}
.status-code-box-container p {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}
.status-code-box-container .box {
  width: 100%;
  background: #FAFAFA;
  border: 1.5px solid #F1F1F1;
  border-radius: 10px;
  position: relative;
  padding: 10px;
  padding-bottom: 30px;
}
.status-code-box-container .box .response-type {
  position: absolute;
  right: 10px;
  color: #858585;
  font-size: 12px;
  font-weight: 400;
  bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/page-maker/components/StatusCodeBox/styles.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;AACJ;AAAI;EACI,eAAA;EACA,gBAAA;EACA,kBAAA;AAER;AACI;EACI,WAAA;EACA,mBAAA;EACA,2BAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;EACA,oBAAA;AACR;AACQ;EACI,kBAAA;EACA,WAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;AACZ","sourcesContent":[".status-code-box-container {\n    width: 100%;\n    height: auto;\n    p {\n        font-size: 16px;\n        font-weight: 500;\n        margin-bottom: 5px;\n    }\n\n    .box {\n        width: 100%;\n        background: #FAFAFA;\n        border: 1.5px solid #F1F1F1;\n        border-radius: 10px;\n        position: relative;\n        padding: 10px;\n        padding-bottom: 30px;\n\n        .response-type {\n            position: absolute;\n            right: 10px;\n            color: #858585;\n            font-size: 12px;\n            font-weight: 400;\n            bottom: 10px;\n        }\n    }\n\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
