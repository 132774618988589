// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.split-container {
  width: 100%;
  height: auto;
  min-height: 100px;
  display: flex;
  gap: 10px;
}

.split-item {
  flex-direction: column;
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/pages/page-maker/components/Split/styles.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,iBAAA;EACA,aAAA;EACA,SAAA;AACJ;;AAEA;EACI,sBAAA;EACA,aAAA;AACJ","sourcesContent":[".split-container {\n    width: 100%;\n    height: auto;\n    min-height: 100px;\n    display: flex;\n    gap: 10px;\n}\n\n.split-item {\n    flex-direction: column;\n    display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
