// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-box-container {
  width: 100%;
  margin-top: 10px;
  height: auto;
  background: #FAFAFA;
  border: 1.5px solid #F1F1F1;
  border-radius: 10px;
  padding: 10px;
  align-items: center;
}
.image-box-container .title {
  margin: 5px;
  font-size: 16px;
  font-weight: 500;
}
.image-box-container img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/page-maker/components/ImageBlock/styles.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,gBAAA;EACA,YAAA;EACA,mBAAA;EACA,2BAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;AACJ;AACI;EACI,WAAA;EACA,eAAA;EACA,gBAAA;AACR;AAEI;EACI,WAAA;EACA,YAAA;EACA,mBAAA;AAAR","sourcesContent":[".image-box-container {\n    width: 100%;\n    margin-top: 10px;\n    height: auto;\n    background: #FAFAFA;\n    border: 1.5px solid #F1F1F1;\n    border-radius: 10px;\n    padding: 10px;\n    align-items: center;\n\n    .title {\n        margin: 5px;\n        font-size: 16px;\n        font-weight: 500;\n    }\n\n    img {\n        width: 100%;\n        height: auto;\n        border-radius: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
